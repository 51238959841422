import React, { useState } from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';
import '../../styles/login.scss';
import { navigate } from 'gatsby';
import { FirebaseContext } from '../../utils/firebase-provider.js';

function LoginForm() {
    const context = React.useContext(FirebaseContext);
    // HERE connecting firebase-provider with components
    // OK setup environment variables
    // OK pending create loading state from provider
    // OK pending show up error messages
    // OK pending show up loading status
    // OK pending option to log out
    // OK display basic logged data as part of profile
    // OK load userprofile once logged in
    // * connect other components (actions, emissions) with firebase-provider
    // other task
    // * forgot password workflow -> github issue
    // * review process to renew tokens -> github issue
    // * signUp user after createUserWithEmailAndPassword -> github issue
    // * process to initialize user for signUp -> github issue

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordEye, setPasswordEye] = useState(true);

    const handlePasswordClick = () => {
        setPasswordEye(!passwordEye);
    };

    const handleSubmit = async () => {
        await context.signIn(email, password);
    };
    React.useEffect(() => {
        if (context.status === 'logged') {
            navigate('/profile/myemissions');
        }
    }, [context.status]);

    return (
        <Layout>
            <div className="entry-container">
                <h1 className="entry-header">Hello Hero</h1>
                {context.message && (
                    <div className="alert alert-info" role="alert">
                        {context.message}
                    </div>
                )}
                {context.error && (
                    <div className="alert alert-warning" role="alert">
                        {context.error}
                    </div>
                )}
                <form className="entry-form">
                    <label htmlFor="email" className="entry-form__label">
                        Email
                    </label>
                    <input
                        type="text"
                        className="entry-form__input"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    ></input>

                    <div className="entry-form__pw-container">
                        <label htmlFor="password" className="entry-form__label">
                            Password
                        </label>
                        <div className="entry-form__pw-input">
                            <input
                                type={passwordEye === true ? 'password' : 'text'}
                                className="entry-form__input"
                                id="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            ></input>
                            {passwordEye === false ? (
                                <FontAwesomeIcon
                                    className="fa-eye-low pw_toggle"
                                    icon={faEyeLowVision}
                                    onClick={handlePasswordClick}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="fa-eye-open pw_toggle"
                                    icon={faEye}
                                    onClick={handlePasswordClick}
                                />
                            )}
                        </div>
                    </div>
                    <label className="entry-form__subtext">Forgot Password</label>

                    <button className="entry-form__button" onClick={handleSubmit}>
                        Log in
                    </button>
                    {context.status === 'logged' && (
                        <button onClick={context.signOut}>Logout</button>
                    )}
                </form>

                {context.loading && (
                    <div className="divider-div">
                        <div className="spinner-border text-success" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                <div className="divider-div">
                    <div className="divider"></div>
                    <span>OR</span>
                    <div className="divider"></div>
                </div>

                <p>Sign in with</p>
                <div className="FA-icons-div">
                    <FontAwesomeIcon
                        className="facebook fa-3x icon"
                        icon={faFacebook}
                        onClick={async () => {
                            await context.signInWithFacebook();
                        }}
                        cursor="pointer"
                    />
                    <div className="google-icon-container icon">
                        <FontAwesomeIcon
                            className="google fa-3x"
                            icon={faGoogle}
                            onClick={async () => {
                                await context.signInWithGoogle();
                            }}
                            cursor="pointer"
                        />
                    </div>

                    {/* <FontAwesomeIcon
                        className="apple fa-3x icon"
                        icon={faApple}
                        cursor="pointer"
                    /> */}
                </div>
                <div className="signup-subtext">
                    <span>Do not have an account? </span>
                    <Link to="/signup" className="subtext-link">
                        Sign Up
                    </Link>
                </div>
                <p className="terms-subtext">
                    By continuing, you agree to the Privacy Policy and Terms of Service
                </p>
            </div>
        </Layout>
    );
}

export default LoginForm;
